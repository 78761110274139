// This is a manifest file that'll be compiled into application.js, which will include all the files
// listed below.
//
// Any JavaScript/Coffee file within this directory, lib/assets/javascripts, vendor/assets/javascripts,
// or any plugin's vendor/assets/javascripts directory can be referenced here using a relative path.
//
// It's not advisable to add code directly here, but if you do, it'll appear at the bottom of the
// compiled file. JavaScript code in this file should be added after the last require_* statement.
//
// Read Sprockets README (https://github.com/rails/sprockets#sprockets-directives) for details
// about supported directives.
//

import localScroll from "jquery.localscroll";
import Foundation from "foundation-sites";
import { enableAutoEventAnalytics } from "@oddcamp/analytics";
import "jquery.scrollto";

import init from "./utils/init";

enableAutoEventAnalytics();

Foundation.addToJquery($);

init(() => {
  function post_url(url) {
    $.post(url);
  }

  // Navigation
  $(".menu-button").on("click", function() {
    if ($(".nav-holder").hasClass("active")) {
      $(".nav-holder")
        .slideUp()
        .removeClass("active");
      $(".menu-button").removeClass("active");
    } else {
      $(".nav-holder")
        .slideDown()
        .addClass("active");
      $(".menu-button").addClass("active");
    }
  });

  setTimeout(function() {
    $("input, textarea").each(function() {
      if ($(this).val() !== "")
        $(this)
          .closest(".login__field")
          .addClass("active");
    });

    if (
      $('input[type="email"]')
        .first()
        .val() != ""
    )
      $('input[type="password"]')
        .closest(".login__field, .input-field")
        .addClass("active");
  }, 100);

  //Label Animation
  $(document)
    .find("input, textarea")
    .each(function() {
      $(this).on("focus", function() {
        $(this)
          .closest(".login__field, .input-field")
          .addClass("active");
        $(this).removeClass("error");
      });

      $(this).on("blur", function() {
        $(document)
          .find("input, textarea")
          .each(function() {
            if ($(this).val().length == 0) {
              $(this)
                .closest(".login__field, .input-field")
                .removeClass("active");
            }
          });
      });

      if ($(this).val()) {
        $(this)
          .closest(".login__field, .input-field")
          .addClass("active");
      }
    });

  //Anchor Links
  localScroll({
    filter: ".anchor-list, .sticky-note",
    duration: 800,
    offset: -20
  });

  localScroll({
    filter: ".lectures__hero__info, .lecture__cta",
    duration: 600,
    offset: -80
  });

  $(".button--lecture-form").on("click", function(e) {
    if (window.ga) {
      var lecture_title = $(this).attr("data-title");
      ga("send", "event", "Föreläsningar", "boka", lecture_title);
    }
  });

  $(".play-promo").on("click", function(e) {
    //Open Promo Video Overlay

    var $promoVideo =
      '<section class="promo"><iframe src="https://player.vimeo.com/video/241694250?autoplay=1&color=50B2E3&byline=0&portrait=0" width="1400" height="788" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe><div class="promo__close"></div></section>';

    $("body").append($promoVideo);

    $(".promo").fadeIn();

    $(".promo__close").on("click", function(e) {
      //Close Promo Video Overlay
      $(".promo").fadeOut(function() {
        $(".promo").remove();
      });
    });

    return false;
  });

  $(".send-form").on("click", function(e) {
    //Contact Form Submit
    $(".new_contact_request input").each(function(index) {
      if (!$(this).val()) {
        $(this).addClass("error");
      }
    });
    if (
      !$("input.email")
        .parsley()
        .isValid()
    ) {
      $("input.email").addClass("error");
    }
    if (
      !$("input.tel")
        .parsley()
        .isValid()
    ) {
      $("input.tel").addClass("error");
    }
    if (
      $(".new_contact_request")
        .parsley()
        .isValid()
    ) {
      $(".new_contact_request").submit();
    }
    return false;
  });

  // Quantity input
  $(
    '<div class="quantity-button quantity-up"></div><div class="quantity-button quantity-down"></div>'
  ).insertAfter(".quantity input");
  $(".quantity").each(function() {
    var spinner = jQuery(this),
      input = spinner.find("#ecommerce_item_quantity"),
      btnUp = spinner.find(".quantity-up"),
      btnDown = spinner.find(".quantity-down"),
      min = input.attr("min"),
      max = input.attr("max");

    btnUp.click(function() {
      var oldValue = parseFloat(input.val());
      if (oldValue >= max) {
        var newVal = oldValue;
      } else {
        var newVal = oldValue + 1;
      }
      spinner.find("#ecommerce_item_quantity").val(newVal);
      spinner.find("#ecommerce_item_quantity").trigger("change");
    });

    btnDown.click(function() {
      var oldValue = parseFloat(input.val());
      if (oldValue <= min) {
        var newVal = oldValue;
      } else {
        var newVal = oldValue - 1;
      }
      spinner.find("#ecommerce_item_quantity").val(newVal);
      spinner.find("#ecommerce_item_quantity").trigger("change");
    });
  });

  // Modals
  $(".cart-trigger").click(function() {
    if ($(".cart-modal").hasClass("active")) {
      $(".cart-modal").removeClass("active");
    } else {
      $(".cart-modal").addClass("active");
    }
  });

  $(".submit--disabled").click(function() {
    if (
      $(this)
        .next(".submit-modal")
        .hasClass("active")
    ) {
      $(this)
        .next(".submit-modal")
        .removeClass("active");
    } else {
      $(this)
        .next(".submit-modal")
        .addClass("active");
    }
  });

  $(document).mouseup(function(e) {
    var container = $(".cart-modal, .submit-modal");

    if (container.has(e.target).length === 0) {
      container.removeClass("active");
    }
  });

  // Checkout
  function orderSwitch() {
    if ($("#ecommerce_order_same_as_invoice_address").is(":checked")) {
      $("#delivery").fadeOut();
      $("#delivery input").each(function() {
        $(this).val("");
      });
    } else {
      $("#delivery").fadeIn();
    }
  }

  $(document).ready(orderSwitch);
  $("#ecommerce_order_same_as_invoice_address").on("change", orderSwitch);

  // Order form
  $(".input__minus").on("click", function() {
    var userNumber = $(this).siblings(".number");
    var currentValue = userNumber.val();
    if (currentValue > 0) {
      currentValue--;
      userNumber.val(currentValue);
    }
    updateTotals();
  });
  $(".input__plus").on("click", function() {
    var userNumber = $(this).siblings(".number");
    var currentValue = userNumber.val();
    currentValue++;
    userNumber.val(currentValue);
    updateTotals();
  });

  $(".options__lectures").on("click", ".remove-single-lecture", function(ev) {
    ev.preventDefault();

    $(ev.target)
      .parent()
      .parent()
      .remove();

    updateTotals();
  });

  $(".add-single-lecture").on("click", function(ev) {
    ev.preventDefault();
    $(".options__lectures").show();

    var newElement = $(".template").clone();
    newElement
      .removeClass("template")
      .addClass("single_lecture_item")
      .appendTo(".options__lectures");
    newElement
      .find(".order_lecture_list")
      .chosen()
      .change(function() {
        updateTotals();
      });

    newElement.find(".input__minus").on("click", function() {
      var userNumber = $(this).siblings(".number");
      var currentValue = userNumber.val();
      if (currentValue > 0) {
        currentValue--;
        userNumber.val(currentValue);
      }
      updateTotals();
    });

    newElement.find(".input__plus").on("click", function() {
      var userNumber = $(this).siblings(".number");
      var currentValue = userNumber.val();
      currentValue++;
      userNumber.val(currentValue);
      updateTotals();
    });

    newElement.find(".number").on("change paste keyup", function() {
      updateTotals();
    });

    updateTotals();
  });

  var updateFormInputs = function() {
    $("#hidden_inputs").empty();
    var lillaBibliotekspaketetUsers = parseInt($("#lillabibliotekspaketet_number").val()) || 0;
    var bibliotekUsers = parseInt($("#bibliotekspaket_number").val()) || 0;
    var uppsagningAvskedandeUsers = parseInt($("#uppsagning_avskedande_number").val()) || 0;
    var singleLectureName = $(".chosen-single span").text() || '';

    $("<input>")
      .attr({
        type: "hidden",
        name: "order[lilla_bibliotekspaketet_users_count]",
        id: "meta_lillabibliotekspaketet",
        value: lillaBibliotekspaketetUsers
      })
      .appendTo("#hidden_inputs");
    $("<input>")
      .attr({
        type: "hidden",
        name: "order[bibliotek_users_count]",
        id: "meta_bibliotek",
        value: bibliotekUsers
      })
      .appendTo("#hidden_inputs");
    $("<input>")
      .attr({
        type: "hidden",
        name: "order[uppsagning_avskedande_users_count]",
        id: "order_uppsagning_avskedande_users_count",
        value: uppsagningAvskedandeUsers
      })
      .appendTo("#hidden_inputs");
    //if single lecture is chosen
    if (singleLectureName !== "") {
      $(".order_lecture_list").each(function(index) {
        if (index !== 0) {
          $("<input>")
            .attr({
              type: "hidden",
              name: "order[lectures][" + (index - 1) + "][video_id]",
              id: "order_lectures_" + (index - 1) + "_video_id",
              value: $(this)
                .chosen()
                .val()
            })
            .appendTo("#hidden_inputs");

          $("<input>")
            .attr({
              type: "hidden",
              name: "order[lectures][" + (index - 1) + "][users_count]",
              id: "order_lectures_" + (index - 1) + "_users_count",
              //TODO: we need to get the correct value, the value used here is obviously not the correct one
              value: $("input.number.user-count")[index].value
            })
            .appendTo("#hidden_inputs");

          $("<input>")
            .attr({
              type: "hidden",
              name: "order[lectures][" + (index - 1) + "][months_count]",
              id: "order_lectures_" + (index - 1) + "_months_count",
              //TODO: we need to get the correct value, the value used here is obviously not the correct one
              value: $("input.number.month-count")[index].value
            })
            .appendTo("#hidden_inputs");
        }
      });
      //order_lectures__video_id has been updated on click
    }
  };

  var updateTotals = function() {
    const bibliotekFixedCharge = 25000;
    const bibliotekExtraUsersCharge = 5000;
    const lillaBibliotekspaketetFixedCharge = 15000;
    const lillaBibliotekspaketetExtraUsersCharge = 2500;

    $(".total").html("");

    var lillaBibliotekspaketetUsers = parseInt($("#lillabibliotekspaketet_number").val()) || 0;
    var bibliotekUsers = parseInt($("#bibliotekspaket_number").val()) || 0;
    var uppsagningAvskedandeUsers = parseInt($("#uppsagning_avskedande_number").val()) || 0;
    var total = 0;

    var lillaBibliotekspaketetSubTotal = (lillaBibliotekspaketetUsers && lillaBibliotekspaketetUsers - 1) * lillaBibliotekspaketetExtraUsersCharge;
    var bibliotekSubTotal = (bibliotekUsers && bibliotekUsers - 1) * bibliotekExtraUsersCharge;
    var uppsagningAvskedandeSubTotal = uppsagningAvskedandeUsers * 1000;

    if (lillaBibliotekspaketetUsers > 0) {
      $(".total").append(
        '<div class="total__item total__item--heading"><div class="left">Lilla Bibliotekspaketet</div><div class="right"></div></div>'
      );

      $(".total").append(
        '<div class="total__item"><div class="left">Abonnemang (inkl 1 användare)</div><div class="right">' + lillaBibliotekspaketetFixedCharge + ' kr</div></div>'
      );

      $(".total").append(
        '<div class="total__item"><div class="left">' +
          (lillaBibliotekspaketetUsers && lillaBibliotekspaketetUsers - 1) +
          ' tilläggsanvändare</div><div class="right">' +
          lillaBibliotekspaketetSubTotal +
          " kr</div></div>"
      );

      lillaBibliotekspaketetSubTotal += lillaBibliotekspaketetFixedCharge;
    }
    if (bibliotekUsers > 0) {
      $(".total").append(
        '<div class="total__item total__item--heading"><div class="left">Bibliotekspaketet</div><div class="right"></div></div>'
      );

      $(".total").append(
        '<div class="total__item"><div class="left">Abonnemang (inkl 1 användare)</div><div class="right">' + bibliotekFixedCharge + ' kr</div></div>'
      );

      $(".total").append(
        '<div class="total__item"><div class="left">' +
          (bibliotekUsers && bibliotekUsers - 1) +
          ' tilläggsanvändare</div><div class="right">' +
          bibliotekSubTotal +
          " kr</div></div>"
      );

      bibliotekSubTotal += bibliotekFixedCharge;
    }

    if (uppsagningAvskedandeUsers > 0) {
      uppsagningAvskedandeSubTotal += 4000;

      $(".total").append(
        '<div class="total__item total__item--heading"><div class="left">Utbildningspaket</div><div class="right"></div></div>'
      );

      $(".total").append(
        '<div class="total__item"><div class="left">' +
        uppsagningAvskedandeUsers +
          ' användare</div><div class="right">' +
          uppsagningAvskedandeSubTotal +
          " kr</div></div>"
      );
    }

    total += lillaBibliotekspaketetSubTotal + bibliotekSubTotal + uppsagningAvskedandeSubTotal;

    if ($(".single_lecture_item").length) {
      $(".total").append(
        '<div class="total__item total__item--heading"><div class="left">Enskilda föreläsningar</div><div class="right"></div></div>'
      );
    }

    $(".single_lecture_item").each(function(i) {
      var userNumber = $(this)
        .find(".user-count")
        .val();
      var monthNumber = $(this)
        .find(".month-count")
        .val();
        if (userNumber <= 5) {
          var firstUserCost = 3900;
          var additionalUserFee = 1000
          var additionalUserCost = additionalUserFee * (userNumber - 1)

          var lectureSubTotal = (firstUserCost + additionalUserCost) * monthNumber
        } else {
          var lectureSubTotal = "Vi hör av oss med en offert."
          $(this)
          .find(".single-lecture-kr")
          .css('display', 'none');
        }
      $(this)
        .find(".single-lecture-price")
        .html(lectureSubTotal);

      var lectureName = $(this)
        .find(".chosen-single span")
        .html();

      $(".total").append(
        '<div class="total__item"><div class="left">' +
          lectureName +
          "<br><small>" +
          userNumber +
          " Användare x " +
          monthNumber +
          ' månader</small></div><div class="right">' +
          lectureSubTotal +
          " <span class='total-kr'> kr</span></div></div>"
      );

      if (userNumber > 0 && userNumber <= 5 ) {
        total += lectureSubTotal;
      } else {
        total = 'Vi hör av oss med en offert.';
        console.log($(this));
      }
    });

    $("#bibliotekspaket_subtotal").html(bibliotekSubTotal);
    $("#lillabibliotekspaketet_subtotal").html(lillaBibliotekspaketetSubTotal);
    $("#uppsagning_avskedande_subtotal").html(uppsagningAvskedandeSubTotal);
    //$("#checkspaket_subtotal").html(chefSubTotal);
    $("#order_total").html(total);

    if (typeof total === 'number') {
      $(".total").append(
        '<div class="total__item  total__item--final"><div class="left">Totalt</div><div class="right">' +
          total +
          " kr exkl moms</div></div>"
      );
    } else {
      $(".total").append(
        '<div class="total__item  total__item--final"><div class="left">Totalt</div><div class="right">' +
          total +
          "</div></div>"
      );
      $(".right .total-kr").css('display', 'none');
    }



    updateFormInputs();
  };

  updateTotals();

  $("#lillabibliotekspaketet_number").on("change paste keyup", function() {
    updateTotals();
  });
  $("#bibliotekspaket_number").on("change paste keyup", function() {
    updateTotals();
  });

  $(document).foundation();
});
