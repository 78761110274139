/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

require.context("../images", true);

// EXTERNAL

window.jQuery = $;
window.$ = $;

import Flatpickr from "flatpickr";
window.Flatpickr = Flatpickr;

import RailsUjs from "@rails/ujs";
RailsUjs.start();

require("ahoy.js");
//require("jquery_ujs");

require("chosen-js/chosen.jquery.min.js");
require("parsleyjs/dist/parsley.min.js");

// INTERNAL

require("../javascripts/cookie-consent.js");
require("../javascripts/slider.js");
require("../javascripts/flatpicker/sv.js");
require("../javascripts/application.js");
require("../javascripts/signed_uploads.js");
require("../javascripts/admin/subscriptions.js");
require("../javascripts/blog.js");
require("../javascripts/videos.js");
require("../javascripts/quizz.js");

// STYLESHEETS

import "../stylesheets/application.scss";
