import { addEventListener } from "@oddcamp/js-utils/src/event"
import { createFocusTrap } from "focus-trap"
import Cookies from "js-cookie"

import init from "./utils/init";

const insertGtmScript = () => {
  const gtmScriptEl = document.getElementById(`gtm-script-template`)

  if (gtmScriptEl && Cookies.get(gtmScriptEl.dataset.cookieName) == `all`) {
    const script = document.createElement(`script`)
    script.type = `text/javascript`
    script.text = gtmScriptEl.innerHTML
    document.head.appendChild(script)
  }
}

// cookie consent dialog

init(() => {

  // Reset cookie settings
  const reset_button = document.querySelector(`.-reset-cookies`)
  addEventListener(reset_button, `click`, (e) => {
    Cookies.remove(reset_button.dataset.cookieName)
    location.reload()
  })

  const container = document.querySelector(`.cookie-consent-overlay`)
  if (!container) return

  //const focusTrap = createFocusTrap(container)
  // focusTrap.activate()
  document.querySelector(`.cookie-consent__cta button`).focus()
  document.documentElement.classList.add(`--disable-scroll`)

  addEventListener(`.cookie-consent__cta button`, `click`, (e, target) => {
    const { value } = target.dataset
    Cookies.set(container.dataset.cookieName, value, { expires: 365 })
    // focusTrap.deactivate()
    document.documentElement.classList.remove(`--disable-scroll`)
    container.remove()

    if (value == `all`) {
      insertGtmScript()
    }
  })
})
