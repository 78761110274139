// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.

import _ from "underscore/underscore";
import Vimeo from "@vimeo/player";
import ahoy from "ahoy.js";

import init from "./utils/init";

init(() => {
  $(".lecture__files a").on("click", function(e) {
    var link = $(this),
      videoID = link.attr("data-video-id"),
      videoTitle = link.attr("data-video-title"),
      videoImageID = link.attr("data-video-image-id"),
      videoImageTitle = link.attr("data-video-image-title");

    console.log(videoID, "videoID");
    console.log(videoTitle, "videoTitle");

    ahoy.track("Video File Download", {
      video_id: videoID,
      video_title: videoTitle,
      video_image_id: videoImageID,
      video_image_title: videoImageTitle
    });
  });

  if ($("#vimeo-player").length) {
    var iframe = $("#vimeo-player")[0];
    var player = new Vimeo(iframe);

    var $video = $(".lecture__video"),
      videoCurrentTime = $video.data("video-current-time");

    player
      .setCurrentTime(videoCurrentTime)
      .then(function(seconds) {
        // seconds = the actual time that the player seeked to
        // It can't be paused when setting current time
        // If it is, it would fire both resume and pause events!
        // player.pause();
      })
      .catch(function(error) {
        switch (error.name) {
          case "RangeError":
            // the time was less than 0 or greater than the video’s duration
            break;

          default:
            // some other error occurred
            break;
        }
      });

    player.on("play", onPlay);
    player.on("pause", onPause);
    player.on("ended", onFinish);
    player.on("timeupdate", onPlayProgress);
  }
});

function onPlayProgress(data) {
  videoEvent({ event: "progress", seconds: data.seconds });
}

function onPlay() {
  videoEvent({ event: "play" });
}

function onPause(data) {
  videoEvent({ event: "pause", seconds: data.seconds });
}

function onFinish() {
  videoEvent({ event: "finish" });
}

function fireVideoEvent(dataObject) {
  var $video = $(".lecture__video");

  $.ajax({
    url: "/api/v1/playbacks/" + $video.data("video-id"),
    type: "PATCH",
    data: dataObject
  });
}

var videoEvent = _.throttle(fireVideoEvent, 1000, { leading: false });
