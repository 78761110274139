import init from "./utils/init";

function isEmpty(el) {
  return !$.trim(el.html());
}

var getPosts = function(url) {
  var result = "";

  $.ajax({
    url: url,
    type: "GET",
    async: false,
    success: function(data) {
      var articles = $(".blog__entry");

      articles.each(function(index) {
        if (
          data[index].better_featured_image &&
          typeof data[index].better_featured_image.media_details.sizes !==
            "undefined"
        ) {
          var file = data[index].better_featured_image.source_url;
          if (
            typeof data[index].better_featured_image.media_details.sizes
              .medium_large !== "undefined"
          ) {
            file =
              data[index].better_featured_image.media_details.sizes.medium_large
                .source_url;
          }
          var image =
            '<div class="blog__entry-image"><a href="' +
            data[index].link +
            '"><img src="' +
            file +
            '"></a></div>';
          $(".blog__entry-content", this).prepend(image);
        }

        var content = $(this).find(".blog__entry-content");
        var footer = $(this).find(".blog__entry-footer");
        content.find("h3").html(data[index].title.rendered);
        content.find(".excerpt").html(data[index].excerpt.rendered);
        var date = new Date(data[index].date);
        var options = { year: "numeric", month: "numeric", day: "numeric" };

        content.find(".article-link").attr("href", data[index].link);

        footer.find(".date").html(date.toLocaleDateString("sv-SE", options));
      });
    }
  });
  return result;
};

var getCases = function(url) {
  var result = "";

  $.ajax({
    url: url,
    type: "GET",
    async: false,
    success: function(data) {
      var articles = $(".thoughts__entry");

      articles.each(function(index) {
        var footer = $(this).find(".thoughts__entry-footer");
        $(this)
          .find("h3")
          .html(data[index].title.rendered);
        $(this)
          .find(".excerpt")
          .html(data[index].excerpt.rendered);
        $(this)
          .find(".article-link")
          .attr("href", data[index].link);
        var date = new Date(data[index].date);
        var options = { year: "numeric", month: "numeric", day: "numeric" };

        footer.find(".date").html(date.toLocaleDateString("sv-SE", options));
      });
    }
  });
  return result;
};

var getArchives = function(url) {
  var result = "";

  $.ajax({
    url: url,
    type: "GET",
    async: false,
    success: function(data) {
      var articles = $(".archive-entry");

      articles.each(function(index) {
        $(this)
          .find("h4")
          .html(data[index].title.rendered);
        $(this)
          .find(".article-link")
          .attr("href", data[index].link);
        var date = new Date(data[index].date);
        var options = { year: "numeric", month: "long" };

        $(this)
          .find(".date")
          .html(date.toLocaleDateString("sv-SE", options));
      });
    }
  });
  return result;
};

init(() => {
  if ($(".section.blog--latest").length) {
    var queryUrl =
      "https://aktuellt.iseskog.se/wp-json/wp/v2/posts?categories=1,3,4";
    var latest = getPosts(queryUrl);
  }

  if ($(".section.thoughts").length) {
    var queryUrl =
      "https://aktuellt.iseskog.se/wp-json/wp/v2/posts?categories=2";
    var cases = getCases(queryUrl);
  }

  if ($(".blog--aktuell-arbetsratt").length) {
    var queryUrl = "https://aktuellt.iseskog.se/wp-json/wp/v2/posts?tags=11";
    var cases = getPosts(queryUrl);
  }

  if ($(".services-blog-archive").length) {
    var queryUrl = "https://aktuellt.iseskog.se/wp-json/wp/v2/posts?tags=12";
    var archives = getArchives(queryUrl);
  }

  // Empty check
  $(".archive-entry").each(function() {
    var emptyCheck = $(this).find("h4");

    if (isEmpty(emptyCheck)) {
      $(this).hide();
    }
  });
});
