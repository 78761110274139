import Swiper, { Thumbs } from 'swiper'

import init from './utils/init'

init(() => {
  const slider = document.querySelector(`.js--slider`)
  if (!slider) return

  const sliderThumbs = new Swiper(`.swiper__thumbs`, {
    spaceBetween: 10,
    slidesPerView: 3,
    freeMode: true,
    watchSlidesProgress: true,
  })

  const mainSlider = new Swiper(`.swiper__main`, {
    modules: [Thumbs],
    spaceBetween: 10,
    thumbs: {
      swiper: sliderThumbs,
    },
  })

  var reloadVideos = () => { 
    var iframes = document.querySelectorAll(`.swiper-slide iframe`)

    Array.prototype.forEach.call(iframes, iframe => { 
      const iframeSrc = iframe.src
      iframe.src = iframeSrc
    })
  }

  mainSlider.on('slideChange', function () {
    reloadVideos()
  })
})
