import Choices from "choices.js";

import init from "../utils/init";

const inputSwitcheroo = (type) => {
  if (type === "Company") {
    new Choices("#subscriptionable_company");

    $("#subscriptionable_user").addClass("subscriptionable--hidden");
    $("#subscriptionable_user").removeClass("subscriptionable--visible");

    $("#subscriptionable_company").addClass("subscriptionable--visible");
    $("#subscriptionable_company").removeClass("subscriptionable--hidden");

    $("#subscriptionable_group__container").show();
    $("#subscriptionable_group").addClass("subscriptionable--visible");
    $("#subscriptionable_group").removeClass("subscriptionable--hidden");

    $(".subscription_user_id").hide();
    $(".subscription_company_id").show();
  } else {
    new Choices("#subscriptionable_user");

    $("#subscriptionable_user").addClass("subscriptionable--visible");
    $("#subscriptionable_user").removeClass("subscriptionable--hidden");

    $("#subscriptionable_company").addClass("subscriptionable--hidden");
    $("#subscriptionable_company").removeClass("subscriptionable--visible");

    $("#subscriptionable_group").addClass("subscriptionable--hidden");
    $("#subscriptionable_group").removeClass("subscriptionable--visible");
    $("#subscriptionable_group__container").hide();

    $(".subscription_user_id").show();
    $(".subscription_company_id").hide();
  }
};




init(() => {
  var type = $("#subscription_subscriptionable_type");

  if (type.length > 0) {
    inputSwitcheroo(type.val());
  }
});

$(document).on("change", "#subscription_subscriptionable_type", function () {
  var type = $(this).val();

  inputSwitcheroo(type);
});

$(document).on("change", "#subscriptionable_company", function (event) {
  var companyID = $(this).val();
  if (companyID) {
    $.ajax("/groups", {
      type: "GET",
      dataType: "script",
      data: {
        company_id: companyID,
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log("AJAX Error: " + textStatus);
      },
    });
  }
});
