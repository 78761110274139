import init from "./utils/init";

var upload, uploadHandler, uploadWithUrl;

uploadWithUrl = function(form, file, presignedUrl, publicUrl) {
  var submit, xhr;
  submit = form.find("input[type='submit']").attr('disabled', true);
  $('.js-signed-upload-status').text('Uploading...');
  xhr = new XMLHttpRequest();
  xhr.open('PUT', presignedUrl);
  xhr.setRequestHeader('Content-Type', file.type);
  xhr.onload = function() {
    submit.removeAttr('disabled');
    if (xhr.status === 200) {
      $('.js-signed-upload-value').val(publicUrl);
      return $('.js-signed-upload-status').text('');
    }
  };
  xhr.onerror = function() {
    submit.removeAttr('disabled');
    return $('.js-signed-upload-status').text('Failed to upload. Try uploading a smaller file.');
  };
  xhr.send(file);
};

upload = function(form, file, url) {
  return $.ajax({
    url: url + '?filename=' + file.name + '&filetype=' + file.type,
    method: 'PUT',
    accept: 'json'
  }).then(function(data) {
    return uploadWithUrl(form, file, data.presigned_url, data.public_url);
  });
};

uploadHandler = function(field) {
  var file, form;
  file = field.files[0];
  if (file === null) {
    return;
  }
  form = $(field).parents("form").eq(0);
  return upload(form, file, field.dataset.presignUrl);
};

init(() => {
  return $('.js-signed-upload').change(function() {
    return uploadHandler(this);
  });
});
