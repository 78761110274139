import ahoy from "ahoy.js";

import init from "./utils/init";

init(() => {
  var questionQty = $(".quizz__questions").attr("data-question-count"),
    quizID = $(".quizz__questions").attr("data-quizz-id"),
    quizTitle = $(".quizz__questions").attr("data-quizz-title"),
    videoID = $(".lecture__video").attr("data-video-id"),
    videoTitle = $(".lecture__video").attr("data-video-title");

  var currentQuestion = 1;
  var answersArr = [];
  $(".quizz__questions-container").css(
    "height",
    $(".quizz__question--" + currentQuestion).outerHeight() + "px"
  );

  $(window).on("resize", function() {
    $(".quizz__questions-container").css(
      "height",
      $(".quizz__question--" + currentQuestion).outerHeight() + "px"
    );
  });

  $('.quizz__questions-container input[type="radio"]').on("click", function() {
    if (currentQuestion === 1) {
      ahoy.track("Quiz Start", {
        quiz_id: quizID,
        quiz_title: quizTitle,
        video_id: videoID,
        video_title: videoTitle
      });
    }

    answersArr[currentQuestion - 1] = {
      question_id: $(this)
        .closest(".quizz__question")
        .attr("data-question-id"),
      option_id: $(this).attr("data-option-id")
    };
    if (
      $(
        ".quizz__questions-container .quizz__question--" +
          currentQuestion +
          " input:checked"
      )
    ) {
      $(".next-question").removeClass("disabled");
    }
    if (currentQuestion == questionQty) {
      $(".complete-quizz")
        .removeClass("hide")
        .addClass("show");
      $(".next-question")
        .removeClass("show")
        .addClass("hide");
    }
  });

  $(".next-question").on("click", function(e) {
    if (!$(this).hasClass("disabled")) {
      currentQuestion++;
      //      $('.quizz__question--'+currentQuestion).css('display', 'block');
      $(".quizz__questions-container").css(
        "height",
        $(".quizz__question--" + currentQuestion).outerHeight() + "px"
      );
      $(".quizz__question-indicator" + currentQuestion).addClass("active");
      $(".previous-question").removeClass("disabled");
      $(".quizz__questions-container").css(
        "transform",
        "translateX(-" +
          parseInt(100 / questionQty) * (currentQuestion - 1) +
          "%)"
      );
    }
    if (
      $(
        ".quizz__questions-container .quizz__question--" +
          currentQuestion +
          " input:checked"
      ).length > 0
    ) {
      $(".next-question").removeClass("disabled");
    } else {
      $(".next-question").addClass("disabled");
    }

    return false;
  });

  $(".previous-question").on("click", function(e) {
    if (currentQuestion != 1) {
      //      $(".quizz__questions-container .quizz__question--"+currentQuestion+" input").prop("checked", false);
      $(".quizz__question-indicator" + currentQuestion).removeClass("active");
      currentQuestion--;
      //      setTimeout(function() {
      //        $('.quizz__question--'+(currentQuestion+1)).css('display', 'none');
      //      }, 250);
      $(".quizz__questions-container").css(
        "height",
        $(".quizz__question--" + currentQuestion).outerHeight() + "px"
      );
      $(".complete-quizz")
        .removeClass("show")
        .addClass("hide");
      $(".next-question")
        .removeClass("hide")
        .addClass("show");
    }
    if (currentQuestion == 1) {
      $(".previous-question").addClass("disabled");
      $(".quizz__questions-container").css(
        "height",
        $(".quizz__question--" + currentQuestion).outerHeight() + "px"
      );
    }
    if (
      $(
        ".quizz__questions-container .quizz__question--" +
          currentQuestion +
          " input:checked"
      )
    ) {
      $(".next-question").removeClass("disabled");
    }
    $(".quizz__questions-container").css(
      "transform",
      "translateX(-" +
        parseInt(100 / questionQty) * (currentQuestion - 1) +
        "%)"
    );

    return false;
  });

  $(".quizz--user .complete-quizz").on("click", function(e) {
    //Complete Quizz
    $.post("/api/v1/quiz_attempts", {
      quiz_id: $(".quizz__questions").attr("data-quizz-id"),
      video_id: $(".lecture__video").attr("data-video-id"),
      answers: answersArr
    }).done(function(data) {
      $(".question__submitted").addClass("show");
      if (data.pass == false) {
        $(".question__submitted")
          .removeClass("passed")
          .addClass("failed");

        $(".question__failed__questions").empty();

        $.each(data.questions, function(i, q) {
          $.each(data.answers, function(ii, a) {
            if (q.id === a.question_id && !a.correct) {
              $(".question__failed__questions").append(
                "<li>" + (i + 1) + ". " + q.message_sv + "</li>"
              );
            }
          });
        });
      } else {
        $(".question__submitted")
          .removeClass("failed")
          .addClass("passed");
      }
    });

    return false;
  });

  $(".quizz--embed .complete-quizz").on("click", function(e) {
    //Complete Quizz
    $.post("/api/v1/quiz_attempts", {
      quiz_id: $(".quizz__questions").attr("data-quizz-id"),
      video_id: $("#lecture-quizz").attr("data-video-id"),
      answers: answersArr
    }).done(function(data) {
      $(".question__submitted").addClass("show");
      if (data.pass == false) {
        $(".question__submitted")
          .removeClass("passed")
          .addClass("failed");

        $(".question__failed__questions").empty();

        $.each(data.questions, function(i, q) {
          $.each(data.answers, function(ii, a) {
            if (q.id === a.question_id && !a.correct) {
              $(".question__failed__questions").append(
                "<li>" + (i + 1) + ". " + q.message_sv + "</li>"
              );
            }
          });
        });
      } else {
        $(".question__submitted")
          .removeClass("failed")
          .addClass("passed");
      }
    });

    return false;
  });

  if ($("#review-form").length === 0) {
    $(".leave-review").attr("href", window.location.href + "?r=1#review-form");
  }

  $(".start-over").on("click", function(e) {
    //Complete Quizz
    questionQty = $(".quizz__questions").attr("data-question-count");
    currentQuestion = 1;
    answersArr = [];
    $(".quizz__questions-container input").prop("checked", false);
    $(".quizz__questions-container").css("transform", "translateX(0%)");
    $(".quizz__question-number span").removeClass("active");
    $(".question__submitted").removeClass("show");
    $(".question__failed__questions").empty();
    $(".complete-quizz")
      .removeClass("show")
      .addClass("hide");
    $(".next-question")
      .removeClass("hide")
      .addClass("show disabled");
    $(".previous-question").addClass("disabled");

    return false;
  });
});
